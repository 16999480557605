<template>
  <div class="std-intro-container center">
    <div class="std-intro column">

      <span clsas="std-intro-title">
        <p class="std-intro-title-text">일반고특화(고교위탁) 직업훈련</p>
      </span>

      <span clsas="std-intro-content">
        <p class="std-intro-content-text">- 고교 3학년 학생이 1년동안 고등학교 본교가 아닌 직업훈련기관 위탁교육을 통해 전문적인 기술능력을 배워 취업이나 진학을 목표로 미래를 준비하는 제도</p>
        <p class="std-intro-content-text">- 국비지원 전액 무료 과정</p>
      </span>

<!--       <span class="std-intro-sub-title">
        <p class="std-intro-sub-title-text">일반고특화(고교위탁) 수강 대상자</p>
      </span> -->

      <hr>
      <table class="std-intro-table std-edu-table">
        <tr class="std-intro-table-content">
          <td>교육대상</td>
          <td class="std-edu-content" colspan="3">
            <p>일반고 예비 3학년</p>
          </td>
        </tr>
        <tr class="std-intro-table-content">
          <td>교육시간</td>
          <td>
            <p class="std-edu-content">월 ~ 금 | 09:00 ~ 16:00</p>
          </td>
          <td>
            <p class="std-edu-content">* 매월 셋째주 월요일 원적교 출석</p>
          </td>
        </tr>
        <tr class="std-intro-table-content">
          <td>교육기간</td>
          <td colspan="3">
            <p class="std-edu-content">2025. 03 ~ 2025. 12</p>
          </td>
        </tr>
        <tr class="std-intro-table-content">
          <td>모집기간</td>
          <td>
            <p class="std-edu-content">~ 2025. 03</p>
          </td>
          <td>
            <p class="std-edu-content">* 개강 후 2주 내 등록가능 | 문의요망</p>
          </td>
        </tr>
        <tr class="std-intro-table-content">
          <td>교육특전</td>
          <td colspan="3" class="std-edu-content">
            <p>1. 국비 100% 전액 무상 교육, 입학장학금 지급</p>
            <p>2. 최대 200,000원 훈련장려금 지급, 모든 실습도구 & 조리복 지원</p>
            <p>3. 1:1 맞춤 밀도있는 진로상담과 전문가의 확실한 취업연계로 탄탄한 진로설계</p>
            <p>4. 10개월 총1,232시간 교육, 국가기술자격증 취득 & 실력있는 현장전문가 양성</p>
            <p>5. 정규 교육과정 외 동아리활동 / 교내외 경연대회 / 창업 현장체험 / 축제 / 창업특강 등 다채로운 창체활동 적극 지원</p>
            <p>6. 성적우수 / 교내외 외식경연 수상 / 출결 / 봉사 / 공로 등 다양한 장학제도 운영</p>
          </td>
        </tr>

        <tr class="std-intro-table-content">
          <td>학과안내</td>
          <td class="std-edu-course-content">
              <p class="std-edu-course-title">[호텔 외식조리 학과]</p>
              <img class="std-edu-image" :src="eduLeft"/>
              <div class="std-edu-course">
                  <span class="std-edu-course-detail">
                      <p>정규과정</p>
                      <p>한식 조리기능사</p>
                      <p>양식 조리기능사</p>
                      <p>중식 조리기능사</p>
                      <p>현장실무요리</p>
                  </span>
                  <span class="std-edu-course-detail">
                      <p>특화과정</p>
                      <p>칵테일 바텐더</p>
                      <p>바리스타 2급자격</p>
                      <p>이태리&프랑스 가정식*</p>
                      <p>카페브런치&홈파티*</p>
                      <p>한식 디저트*</p>
                      <p>교내외 경연</p>
                      <p>외식현장체험</p>
                  </span>
              </div>
          </td>
          <td class="std-edu-course-content">
              <p class="std-edu-course-title">[제과제빵&바리스타 학과]</p>
              <img class="std-edu-image" :src="eduRight"/>
              <div class="std-edu-course">
                  <span class="std-edu-course-detail">
                      <p>정규과정</p>
                      <p>제과 기능사</p>
                      <p>제빵 기능사</p>
                      <p>케이크 데코레이션</p>
                      <p>제과현장실무</p>
                      <p>바리스타2급자격</p>
                  </span>
                  <span class="std-edu-course-detail">
                      <p>특화과정</p>
                      <p>칵테일 바텐더</p>
                      <p>제과 디저트*</p>
                      <p>시그니처 디저트 카페*</p>
                      <p>카페브런치&홈파티음식*</p>
                      <p>교내외 경연</p>
                      <p>카페창업 현장체험</p>
                  </span>
              </div>
          </td>

        </tr>
    
        <tr class="std-intro-table-content">
            <td class="std-edu-stroke std-edu-content" colspan="3" style="border:none">
                <p>□ [한식디저트 | 이태리&프랑스 가정식 | 시그니처 디저트 카페 | 제과디저트 | 카페브런치&홈파티 음식]강의는 한선생만의 특화과정입니다.</p>
                <p>□ 정규과정 및 특화과정은 고교위탁 훈련생 대상 전액 무료로 진행됩니다.</p>
                <p>□ 수요조사를 통해 학생들이 원하고 필요로 하는 신규 특화과정을 개설할 예정입니다.</p>
            </td>
        </tr>

        <tr class="std-intro-table-content">
            <td>수강신청</td>
            <td colspan="3">
                <p>1. 소속학교 선생님과 진로상담 후 위탁신청</p>
                <p>2. 한선생직업전문학교 위탁지원접수</p>
                <p>3. 한선생직업전문학교 면접, 훈련상담</p>
                <p>4. 합격통보</p>
                <p>5. 고용24 회원가입 및 공인인증 등록</p>
                <p>6. 거주지관할 고용지원센터 방문</p>
                <p>7. 내일배움카드 발급 신청</p>
                <p>8. 한선생직업전문학교 고교위탁 훈련과정등록</p>
                <p>9. 일반고 특화 고교위탁 훈련수강</p>
            </td>
        </tr>

        <tr class="std-intro-table-content">
            <td>구비서류</td>
            <td style="vertical-align: text-top;">
                <p class="std-edu-course-title">[본인준비]</p>
                <p>- 입학지원서 1부</p>
                <p>- 신분증(주민등록증, 청소년증, 여권 등 신분증명서)</p>
                <p>- 부모님동의서 1부*</p>
                <p>- 농협이나 신한은행 본인명의 계좌 및 공인인증서 발급</p>
                <p>- 반명함 사진 1매</p>
            </td>
            <td style="vertical-align: text-top;">
                <p class="std-edu-course-title">[학교발급]</p>
                <p>- 학교장 추천서 1부*</p>
                <p>- 재학증명서 1부</p>
                <p>- 생활기록부 1부</p>
            </td>
        </tr>

        <tr class="std-intro-table-content">
            <td class="std-edu-content" colspan="3" style="border:none;">
                <a style="color:#2b96ed; text-decoration-color: #2b96ed; text-decoration-line: underline;"
                href="/uploads/%ED%95%9C%EC%84%A0%EC%83%9D%EC%A7%81%EC%97%85%EC%A0%84%EB%AC%B8%ED%95%99%EA%B5%90%202025%EB%85%84%20%EC%9D%BC%EB%B0%98%EA%B3%A0%20%EC%A0%9C%EC%B6%9C%EC%84%9C%EB%A5%98%20%EB%AA%A8%EC%9D%8C.pdf">
                    □ 필수서류 다운로드(입학지원서, 부모님동의서, 학교장 추천서)
                </a>
            </td>
        </tr>

      </table>

    </div>
  </div>
</template>

<script>
import eduLeft from '../../assets/images/edu_left.png';
import eduRight from '../../assets/images/edu_right.png';

export default {
  data() {
    return {
      eduLeft, eduRight
    }
  }
}

</script>

<style scoped>

.std-edu-content {
    text-align: left!important;
}

.std-intro-table-content>td:first-child {
    width: 25%;
}

.std-edu-image {
    width: 50%;
}

.std-edu-course-content {
    text-align: center;
    vertical-align: text-top;
}

.std-edu-course {
    display: flex;
    justify-content: center;
}

@media only screen and (max-width: 768px) {
    .std-edu-course {
        flex-direction: column;
    }

    .std-intro-table-content>td, th, p {
        font-size: .8em;
    }

    .std-edu-course-title {
        font-size: 1em!important;
    }
}

.std-edu-course-detail {
    margin-left: 8px;
    margin-right: 8px;
}

.std-edu-course-detail>p:first-child {
    font-size: 1.2em;
    font-weight: 600;
    margin-bottom: 8px;
    margin-top: 16px;
}

.std-edu-course-title {
    margin-bottom: 8px;
    font-size: 1.4em;
    font-weight: 600;
}

.std-edu-stroke {
    font-weight: 600;
}
</style>
